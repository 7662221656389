@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

/*
Custom CSS

Etc...
1. Avatar
2. Badge
3. Card
4. Date picker
5. Drawer
6. Dropdown
7. Calender
8. Rich Text Editor
9. Secondary Header
10. Side Nav
11. Header
12. Uplaod
13. Tooltip
14. Toast
15. Timeline
16. Tag
17. Tab nav, list
18. Table
19. Switcher
20. Steps
21. Skeleton
22. Select
23. Segment
24. Radio
25. Progress
26. Pagination
27. Notification
28. Menu Item
29. Input
30. Form Item & Label
31. Dialog
*/

/* ================================================================================================================ */
/* Etc... */
/* ================================================================================================================ */

html {
  -webkit-text-size-adjust: 100%;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
  line-height: 1.5;
  tab-size: 4;
}
body {
  line-height: inherit;
  margin: 0;
}

*,
:after,
:before {
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
}
:after,
:before {
  --tw-content: "";
}
html {
  -webkit-text-size-adjust: 100%;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
  line-height: 1.5;
  tab-size: 4;
}
body {
  line-height: inherit;
  margin: 0;
}
hr {
  border-top-width: 1px;
  color: inherit;
  height: 0;
}
abbr:where([title]) {
  text-decoration: underline dotted;
}

a {
  color: inherit;
  text-decoration: inherit;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
pre,
samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    Liberation Mono, Courier New, monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: initial;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
table {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
}
button,
select {
  text-transform: none;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
  background-color: initial;
  background-image: none;
}
:-moz-focusring {
  outline: auto;
}
:-moz-ui-invalid {
  box-shadow: none;
}
progress {
  vertical-align: initial;
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
summary {
  display: list-item;
}
blockquote,
dd,
dl,
figure,
hr,
p,
pre {
  margin: 0;
}
fieldset {
  margin: 0;
}
fieldset,
legend {
  padding: 0;
}
menu,
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
textarea {
  resize: vertical;
}
input::placeholder,
textarea::placeholder {
  color: #9ca3af;
  opacity: 1;
}
[role="button"],
button {
  cursor: pointer;
}
:disabled {
  cursor: default;
}
audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: block;
  vertical-align: middle;
}
img,
video {
  height: auto;
  max-width: 100%;
}
body {
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  color: rgb(107 114 128 / var(--tw-text-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1.5;
}
.dark body,
body {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}
.dark body {
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
body {
  -webkit-font-smoothing: antialiased;
}

.h1,
h1 {
  color: rgb(17 24 39 / var(--tw-text-opacity));
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 2.5rem;
}
.dark .h1,
.dark h1,
.h1,
h1 {
  --tw-text-opacity: 1;
}
.dark .h1,
.dark h1 {
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.h2,
h2 {
  color: rgb(17 24 39 / var(--tw-text-opacity));
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.25rem;
}
.dark .h2,
.dark h2,
.h2,
h2 {
  --tw-text-opacity: 1;
}
.dark .h2,
.dark h2 {
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.h3,
h3 {
  color: rgb(17 24 39 / var(--tw-text-opacity));
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
}
.dark .h3,
.dark h3,
.h3,
h3 {
  --tw-text-opacity: 1;
}
.dark .h3,
.dark h3 {
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.h4,
h4 {
  color: rgb(17 24 39 / var(--tw-text-opacity));
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
}
.dark .h4,
.dark h4,
.h4,
h4 {
  --tw-text-opacity: 1;
}
.dark .h4,
.dark h4 {
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.h5,
h5 {
  color: rgb(17 24 39 / var(--tw-text-opacity));
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
}
.dark .h5,
.dark h5,
.h5,
h5 {
  --tw-text-opacity: 1;
}
.dark .h5,
.dark h5 {
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.h6,
h6 {
  color: rgb(17 24 39 / var(--tw-text-opacity));
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}
.dark .h6,
.dark h6,
.h6,
h6 {
  --tw-text-opacity: 1;
}
.dark .h6,
.dark h6 {
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

hr {
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}
.dark hr,
hr {
  --tw-border-opacity: 1;
}
.dark hr {
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.heading-text {
  color: rgb(17 24 39 / var(--tw-text-opacity));
}
.dark .heading-text,
.heading-text {
  --tw-text-opacity: 1;
}
.dark .heading-text {
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
*,
::backdrop,
:after,
:before {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.button {
  font-weight: 600;
  white-space: nowrap;
}
.button:focus {
  outline: 2px solid #0000;
  outline-offset: 2px;
}
.button.radius-round {
  border-radius: 0.375rem;
}
.button.radius-circle {
  border-radius: 9999px;
}
.button.radius-none {
  border-radius: 0;
}

/* ================================================================================================================ */
/* 1. Avatar */
/* ================================================================================================================ */
.avatar {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
  color: rgb(243 244 246 / var(--tw-text-opacity));
  display: inline-block;
  position: relative;
}
.dark .avatar {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.avatar-sm {
  height: 2rem;
  line-height: 2rem;
  width: 2rem;
}
.avatar-md {
  height: 2.5rem;
  width: 2.5rem;
}
.avatar-lg,
.avatar-md {
  line-height: 2.5rem;
}
.avatar-lg {
  height: 3rem;
  width: 3rem;
}
.avatar-rounded {
  border-radius: 0.375rem;
}
.avatar-square {
  border-radius: 0;
}
.avatar-circle {
  border-radius: 9999px;
}
.avatar-img {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.avatar-string {
  align-items: center;
  display: flex;
  left: 50%;
  position: absolute;
  transform-origin: center;
}
.avatar-inner-sm {
  height: 2rem;
}
.avatar-inner-md {
  height: 2.5rem;
}
.avatar-inner-lg {
  height: 3rem;
}
.avatar-icon {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
.avatar-icon-sm {
  font-size: 1.125rem;
}
.avatar-icon-md,
.avatar-icon-sm {
  line-height: 1.75rem;
}
.avatar-icon-md {
  font-size: 1.25rem;
}
.avatar-icon-lg {
  font-size: 1.5rem;
  line-height: 2rem;
}
.avatar-group {
  display: inline-flex;
}
.avatar-group-chained > .avatar,
.avatar-group-chained > .tooltip-wrapper {
  margin-left: -0.5rem;
}
[dir="rtl"] .avatar-group-chained > .avatar,
[dir="rtl"] .avatar-group-chained > .tooltip-wrapper {
  margin-right: -0.5rem;
}
.avatar-group-chained > .avatar:first-child,
.avatar-group-chained > .tooltip-wrapper:first-child {
  margin-left: 0;
}
[dir="rtl"] .avatar-group-chained > .avatar:first-child,
[dir="rtl"] .avatar-group-chained > .tooltip-wrapper:first-child {
  margin-right: 0;
}

/* ================================================================================================================ */
/* 2. Badge */
/* ================================================================================================================ */
.badge {
  padding: 0.125rem 0.5rem;
}
.badge,
.badge-dot {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
  border-radius: 9999px;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-size: 0.75rem;
  line-height: 1rem;
}
.badge-dot {
  height: 0.5rem;
  width: 0.5rem;
}
.badge-wrapper {
  display: flex;
  position: relative;
}
.badge-inner {
  --tw-translate-y: -50%;
  position: absolute;
  top: 0;
  z-index: 10;
}
.badge-inner,
[dir="ltr"] .badge-inner {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
[dir="ltr"] .badge-inner {
  --tw-translate-x: 50%;
  right: 0;
}
[dir="rtl"] .badge-inner {
  --tw-translate-x: -50%;
  left: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

/* ================================================================================================================ */
/* 3. Card */
/* ================================================================================================================ */
.card {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 0.5rem;
}
.card,
.dark .card {
  --tw-bg-opacity: 1;
}
.dark .card {
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
.card-border {
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  border-width: 1px;
}
.card-border,
.dark .card-border {
  --tw-border-opacity: 1;
}
.dark .card-border {
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.card-shadow {
  --tw-border-opacity: 1;
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  border-bottom-width: 1px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.dark .card-shadow {
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
  border-style: none;
}
.card-header-border,
.dark .card-shadow {
  --tw-border-opacity: 1;
}
.card-header-border {
  border-bottom-width: 1px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}
.dark .card-header-border {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.card-header-extra {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.card-footer {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.card-footer-border {
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  border-top-width: 1px;
}
.card-footer-border,
.dark .card-footer-border {
  --tw-border-opacity: 1;
}
.dark .card-footer-border {
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.card-footer,
.card-header {
  padding: 0.75rem 1.25rem;
}
.card-body {
  padding: 1.25rem;
}
.card-gutterless {
  padding: 0;
}
/* ================================================================================================================ */
/* 4. Date picker */
/* ================================================================================================================ */
.date-picker {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 0.5rem;
  max-width: 20rem;
  padding: 1rem;
  z-index: 40;
}
.dark .date-picker,
.date-picker {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.dark .date-picker {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
.date-picker-panel {
  border-radius: 0.5rem;
}
.date-picker-header-label {
  color: rgb(17 24 39 / var(--tw-text-opacity));
  cursor: pointer;
  margin-left: 0.125rem;
  margin-right: 0.125rem;
  -webkit-user-select: none;
  user-select: none;
}
.dark .date-picker-header-label,
.date-picker-header-label {
  --tw-text-opacity: 1;
}
.dark .date-picker-header-label {
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.date-picker-current-month {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}
.dark .date-picker-current-month {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.date-picker-other-month {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.dark .date-picker-other-month {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}
.date-picker-available:hover,
.date-picker-selected-date {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.date-picker-today {
  border-width: 1px;
}
.date-picker-month-cell {
  font-weight: 600;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  text-align: center;
}
.date-picker-month-cell-disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
  cursor: not-allowed;
  opacity: 0.3;
}
.date-picker-month-cell-inner {
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  -webkit-user-select: none;
  user-select: none;
}
.date-picker-year-cell {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  text-align: center;
}
.date-picker-year-cell-inner {
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 600;
  padding: 0.5rem 1rem;
  -webkit-user-select: none;
  user-select: none;
}
.date-picker-year-cell-disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
  cursor: not-allowed;
  opacity: 0.3;
}
.picker {
  border-radius: 0.5rem;
  z-index: 40;
}
.picker-panel {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 0.5rem;
  padding: 1rem;
}
.dark .picker-panel,
.picker-panel {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.dark .picker-panel {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}
.picker-view {
  display: flex;
  gap: 1.5rem;
  min-width: 260px;
}
.picker-table,
.picker-view {
  width: 100%;
}
.picker-table {
  border-collapse: collapse;
}
.picker-header-arrow {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 1.125rem;
  height: 2.5rem;
  line-height: 1.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  -webkit-user-select: none;
  user-select: none;
}
[dir="rtl"] .picker-header-arrow {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.picker-header-label {
  color: rgb(17 24 39 / var(--tw-text-opacity));
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
  margin-left: 0.125rem;
  margin-right: 0.125rem;
  -webkit-user-select: none;
  user-select: none;
}
.dark .picker-header-label,
.picker-header-label {
  --tw-text-opacity: 1;
}
.dark .picker-header-label {
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.month-table,
.year-table {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.date-picker-cell {
  height: 2.25rem;
  padding: 0;
  text-align: center;
  width: 2.25rem;
}
.date-picker-cell-content {
  font-weight: 400;
  height: 100%;
  width: 100%;
}
.date-picker-cell-current-month {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}
.dark .date-picker-cell-current-month {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.date-picker-cell-hoverable:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.dark .date-picker-cell-hoverable:hover {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  color: rgb(209 213 219 / var(--tw-text-opacity));
}
.date-picker-cell-disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
  cursor: not-allowed;
  opacity: 0.3;
}
.date-picker-week-cell {
  font-weight: 600;
}
.week-day-cell {
  height: 1.75rem;
}
.day-picker,
.month-picker,
.year-picker {
  width: 100%;
}
.month-picker-cell,
.year-picker-cell {
  border-radius: 0.5rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  text-align: center;
}
.month-picker-cell-disabled,
.year-picker-cell-disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
  cursor: not-allowed;
  opacity: 0.3;
}

/* ================================================================================================================ */
/* 5. Drawer */
/* ================================================================================================================ */
.drawer-content {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  display: flex;
  flex-direction: column;
  position: absolute;
}
.dark .drawer-content {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
.drawer-content.vertical {
  height: 100%;
}
.drawer-content.horizontal {
  width: 100%;
}
.drawer-header {
  align-items: center;
  border-bottom-width: 1px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
}
.dark .drawer-header,
.drawer-header {
  --tw-border-opacity: 1;
}
.dark .drawer-header {
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}
.drawer-body {
  height: 100%;
  overflow-y: auto;
  padding: 1.5rem;
}
.drawer-footer {
  align-items: center;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  border-top-width: 1px;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
}
.dark .drawer-footer,
.drawer-footer {
  --tw-border-opacity: 1;
}
.dark .drawer-footer {
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}
.drawer-open.drawer-lock-scroll {
  overflow: hidden;
}
.drawer-overlay {
  --tw-bg-opacity: 0.8;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.3s ease-in-out;
  z-index: 30;
}
.drawer-overlay-after-open {
  opacity: 1;
}
.drawer-overlay-before-close {
  opacity: 0;
}

/* ================================================================================================================ */
/* 6. Dropdown */
/* ================================================================================================================ */
.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-menu {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
  --tw-ring-opacity: 0.05;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 0.375rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  min-width: 160px;
  padding: 0.375rem;
  position: absolute;
  z-index: 30;
}
.dropdown-menu:focus {
  outline: 2px solid #0000;
  outline-offset: 2px;
}
.dark .dropdown-menu {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.dropdown-menu.top-start {
  bottom: 100%;
  transform-origin: bottom;
}
[dir="ltr"] .dropdown-menu.top-start {
  left: 0;
}
[dir="rtl"] .dropdown-menu.top-start {
  right: 0;
}
.dropdown-menu.top-center {
  left: 50%;
}
.dropdown-menu.top-center,
.dropdown-menu.top-end {
  bottom: 100%;
  transform-origin: bottom;
}
[dir="ltr"] .dropdown-menu.top-end {
  right: 0;
}
[dir="rtl"] .dropdown-menu.top-end {
  left: 0;
}
.dropdown-menu.bottom-start {
  top: 100%;
  transform-origin: top;
}
[dir="ltr"] .dropdown-menu.bottom-start {
  left: 0;
}
[dir="rtl"] .dropdown-menu.bottom-start {
  right: 0;
}
.dropdown-menu.bottom-center {
  left: 50%;
}
.dropdown-menu.bottom-center,
.dropdown-menu.bottom-end {
  top: 100%;
  transform-origin: top;
}
[dir="ltr"] .dropdown-menu.bottom-end {
  right: 0;
}
[dir="rtl"] .dropdown-menu.bottom-end {
  left: 0;
}
.dropdown-menu.middle-start-top {
  left: 100%;
  top: 0;
  transform-origin: top;
}
.dropdown-menu.middle-start-bottom {
  bottom: 0;
  left: 100%;
  transform-origin: bottom;
}
.dropdown-menu.middle-end-top {
  right: 100%;
  top: 0;
  transform-origin: top;
}
.dropdown-menu.middle-end-bottom {
  bottom: 0;
  right: 100%;
  transform-origin: bottom;
}
.dropdown-toggle-default {
  cursor: pointer;
  display: inline-block;
  padding: 0.5rem;
}
.dropdown-toggle-default.dropdown-toggle-disabled {
  opacity: 0.6;
}
.dropdown-submenu {
  left: 100%;
  top: 0;
  transform-origin: top;
}
.dropdown-submenu-item {
  justify-content: space-between;
}
.dropdown-toggle-disabled {
  cursor: not-allowed;
}

/* ================================================================================================================ */
/* 7. Calender */
/* ================================================================================================================ */
.calendar,
.calendar .fc {
  height: 100%;
}
.calendar .fc-theme-standard .fc-scrollgrid {
  border-width: 0;
}
.calendar .fc-theme-standard td,
.calendar .fc-theme-standard th {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}
.dark .calendar .fc-theme-standard td,
.dark .calendar .fc-theme-standard th {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.calendar .fc-theme-standard td:last-child,
.calendar .fc-theme-standard th:last-child {
  border-right: 0;
}
.calendar .fc .fc-col-header-cell-cushion {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}
.dark .calendar .fc .fc-col-header-cell-cushion {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.calendar .fc-col-header-cell {
  border-width: 0 0 1px;
}
.calendar .fc .fc-daygrid-day-number {
  font-weight: 600;
  padding: 0.5rem;
}
.calendar .fc-view {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  border-radius: 0.5rem;
  border-width: 1px;
}
.calendar .fc-view,
.dark .calendar .fc-view {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
}
.dark .calendar .fc-view {
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.calendar .fc-daygrid-dot-event .fc-event-title {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}
.dark .calendar .fc-daygrid-dot-event .fc-event-title {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.calendar .fc .fc-button {
  border-radius: 0.375rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.calendar .fc .fc-toolbar {
  align-items: flex-start;
  flex-direction: column;
  gap: 0.5rem;
}
@media (min-width: 1024px) {
  .calendar .fc .fc-toolbar {
    align-items: center;
    flex-direction: row;
  }
}
.calendar .fc .fc-button-primary {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  border-width: 1px;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  font-weight: 600;
}
.calendar .fc .fc-button-primary,
.dark .calendar .fc .fc-button-primary {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}
.dark .calendar .fc .fc-button-primary {
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.calendar .fc .fc-button-primary:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.calendar .fc .fc-button-primary:hover:hover {
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  color: rgb(17 24 39 / var(--tw-text-opacity));
}
.calendar .fc .fc-button-primary:hover:hover,
.dark .calendar .fc .fc-button-primary:hover:hover {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}
.dark .calendar .fc .fc-button-primary:hover:hover {
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.calendar .fc .fc-button-primary:disabled {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  border-width: 1px;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  cursor: not-allowed;
}
.calendar .fc .fc-button-primary:disabled,
.dark .calendar .fc .fc-button-primary:disabled {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}
.dark .calendar .fc .fc-button-primary:disabled {
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.calendar .fc .fc-button-primary:disabled:hover {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  border-width: 1px;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}
.calendar .fc .fc-button-primary:disabled:hover,
.dark .calendar .fc .fc-button-primary:disabled:hover {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}
.dark .calendar .fc .fc-button-primary:disabled:hover {
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.calendar .fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.calendar .fc .fc-button-primary:not(:disabled):active:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.calendar .fc .fc-button-primary:not(:disabled).fc-button-active,
.calendar .fc .fc-button-primary:not(:disabled):active {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  border-width: 1px;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}
.dark .calendar .fc .fc-button-primary:not(:disabled).fc-button-active,
.dark .calendar .fc .fc-button-primary:not(:disabled):active {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.calendar .fc .fc-dayGridMonth-button,
.calendar .fc .fc-timeGridDay-button,
.calendar .fc .fc-timeGridWeek-button,
.calendar .fc .fc-today-button {
  padding-left: 2rem;
  padding-right: 2rem;
  text-transform: capitalize;
}
.calendar .fc .fc-highlight,
.calendar .fc-daygrid-dot-event.fc-event-mirror,
.calendar .fc-daygrid-dot-event:hover {
  background-color: initial;
}
.calendar .fc .fc-daygrid-event {
  border-radius: 0.375rem;
  margin-bottom: 0.5rem;
  padding: 0;
}
.calendar .fc-h-event {
  background-color: initial;
  border-width: 0;
}
.calendar .fc-event-selected,
.calendar .fc-event:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.calendar .fc-event-selected:after,
.calendar .fc-event:focus:after {
  background-color: initial;
}
.calendar .fc .fc-cell-shaded,
.calendar .fc .fc-day-disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}
.dark .calendar .fc .fc-cell-shaded,
.dark .calendar .fc .fc-day-disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}
.calendar .fc-daygrid-bg-harness .fc-highlight {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.dark .calendar .fc-daygrid-bg-harness .fc-highlight {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}
.calendar .fc-daygrid-event {
  font-size: 0.75rem;
  line-height: 1rem;
}
.calendar .fc .custom-calendar-event {
  align-items: center;
  border-radius: 0.375rem;
  display: flex;
  min-height: 28px;
  overflow: hidden;
  padding: 0.375rem;
  text-overflow: ellipsis;
  width: 100%;
}
.calendar .fc-v-event {
  background-color: initial;
  border-width: 0;
}
.calendar .fc .fc-daygrid-body,
.calendar .fc .fc-scrollgrid-section table {
  width: 100% !important;
}
.calendar .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-left: 0;
  margin-right: 0;
}
.calendar .fc-toolbar-chunk {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}
@media (min-width: 640px) {
  .calendar .fc-toolbar-chunk {
    flex-direction: row;
    width: auto;
  }
}

/* ================================================================================================================ */
/* 8. Rich Text Editor */
/* ================================================================================================================ */
.rich-text-editor .ql-toolbar.ql-snow {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-width: 1px;
}
.dark .rich-text-editor .ql-toolbar.ql-snow {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.rich-text-editor .ql-container.ql-snow {
  --tw-border-opacity: 1;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  border-width: 1px;
}
.dark .rich-text-editor .ql-container.ql-snow {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.rich-text-editor .ql-editor {
  min-height: 120px;
  padding: 1.5rem;
}
.rich-text-editor .ql-snow .ql-stroke {
  stroke: #4b5563;
}
.dark .rich-text-editor .ql-snow .ql-stroke {
  stroke: #f3f4f6;
}
.rich-text-editor .ql-snow .ql-picker {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}
.dark .rich-text-editor .ql-snow .ql-picker {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.rich-text-editor .ql-snow .ql-toolbar button,
.rich-text-editor .ql-snow.ql-toolbar button {
  border-radius: 0.25rem;
}
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-item:hover,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-label:hover,
.rich-text-editor .ql-snow .ql-toolbar button.ql-active,
.rich-text-editor .ql-snow .ql-toolbar button:focus,
.rich-text-editor .ql-snow .ql-toolbar button:hover,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-item:hover,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-label:hover,
.rich-text-editor .ql-snow.ql-toolbar button.ql-active,
.rich-text-editor .ql-snow.ql-toolbar button:focus,
.rich-text-editor .ql-snow.ql-toolbar button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.dark .rich-text-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected,
.dark .rich-text-editor .ql-snow .ql-toolbar .ql-picker-item:hover,
.dark .rich-text-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.dark .rich-text-editor .ql-snow .ql-toolbar .ql-picker-label:hover,
.dark .rich-text-editor .ql-snow .ql-toolbar button.ql-active,
.dark .rich-text-editor .ql-snow .ql-toolbar button:focus,
.dark .rich-text-editor .ql-snow .ql-toolbar button:hover,
.dark .rich-text-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.dark .rich-text-editor .ql-snow.ql-toolbar .ql-picker-item:hover,
.dark .rich-text-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.dark .rich-text-editor .ql-snow.ql-toolbar .ql-picker-label:hover,
.dark .rich-text-editor .ql-snow.ql-toolbar button.ql-active,
.dark .rich-text-editor .ql-snow.ql-toolbar button:focus,
.dark .rich-text-editor .ql-snow.ql-toolbar button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.rich-text-editor
  .ql-snow
  .ql-toolbar
  .ql-picker-item.ql-selected
  .ql-stroke-miter,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.rich-text-editor
  .ql-snow
  .ql-toolbar
  .ql-picker-label.ql-active
  .ql-stroke-miter,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.rich-text-editor .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.rich-text-editor .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.rich-text-editor .ql-snow .ql-toolbar button:focus .ql-stroke,
.rich-text-editor .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.rich-text-editor .ql-snow .ql-toolbar button:hover .ql-stroke,
.rich-text-editor .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.rich-text-editor
  .ql-snow.ql-toolbar
  .ql-picker-item.ql-selected
  .ql-stroke-miter,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.rich-text-editor
  .ql-snow.ql-toolbar
  .ql-picker-label.ql-active
  .ql-stroke-miter,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.rich-text-editor .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.rich-text-editor .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.rich-text-editor .ql-snow.ql-toolbar button:focus .ql-stroke,
.rich-text-editor .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.rich-text-editor .ql-snow.ql-toolbar button:hover .ql-stroke,
.rich-text-editor .ql-snow.ql-toolbar button:hover .ql-stroke-miter {
  stroke: #111827;
}
.dark
  .rich-text-editor
  .ql-snow
  .ql-toolbar
  .ql-picker-item.ql-selected
  .ql-stroke,
.dark
  .rich-text-editor
  .ql-snow
  .ql-toolbar
  .ql-picker-item.ql-selected
  .ql-stroke-miter,
.dark .rich-text-editor .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.dark
  .rich-text-editor
  .ql-snow
  .ql-toolbar
  .ql-picker-item:hover
  .ql-stroke-miter,
.dark
  .rich-text-editor
  .ql-snow
  .ql-toolbar
  .ql-picker-label.ql-active
  .ql-stroke,
.dark
  .rich-text-editor
  .ql-snow
  .ql-toolbar
  .ql-picker-label.ql-active
  .ql-stroke-miter,
.dark .rich-text-editor .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.dark
  .rich-text-editor
  .ql-snow
  .ql-toolbar
  .ql-picker-label:hover
  .ql-stroke-miter,
.dark .rich-text-editor .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.dark .rich-text-editor .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.dark .rich-text-editor .ql-snow .ql-toolbar button:focus .ql-stroke,
.dark .rich-text-editor .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.dark .rich-text-editor .ql-snow .ql-toolbar button:hover .ql-stroke,
.dark .rich-text-editor .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.dark
  .rich-text-editor
  .ql-snow.ql-toolbar
  .ql-picker-item.ql-selected
  .ql-stroke,
.dark
  .rich-text-editor
  .ql-snow.ql-toolbar
  .ql-picker-item.ql-selected
  .ql-stroke-miter,
.dark .rich-text-editor .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.dark
  .rich-text-editor
  .ql-snow.ql-toolbar
  .ql-picker-item:hover
  .ql-stroke-miter,
.dark
  .rich-text-editor
  .ql-snow.ql-toolbar
  .ql-picker-label.ql-active
  .ql-stroke,
.dark
  .rich-text-editor
  .ql-snow.ql-toolbar
  .ql-picker-label.ql-active
  .ql-stroke-miter,
.dark .rich-text-editor .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.dark
  .rich-text-editor
  .ql-snow.ql-toolbar
  .ql-picker-label:hover
  .ql-stroke-miter,
.dark .rich-text-editor .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.dark .rich-text-editor .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.dark .rich-text-editor .ql-snow.ql-toolbar button:focus .ql-stroke,
.dark .rich-text-editor .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.dark .rich-text-editor .ql-snow.ql-toolbar button:hover .ql-stroke,
.dark .rich-text-editor .ql-snow.ql-toolbar button:hover .ql-stroke-miter {
  stroke: #f3f4f6;
}
.rich-text-editor .ql-snow .ql-picker-options .ql-picker-item {
  border-radius: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.rich-text-editor
  .ql-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}
.dark
  .rich-text-editor
  .ql-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.rich-text-editor .ql-snow .ql-picker-options {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 0.5rem;
}
.dark .rich-text-editor .ql-snow .ql-picker-options {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-item:hover,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.rich-text-editor .ql-snow .ql-toolbar .ql-picker-label:hover,
.rich-text-editor .ql-snow .ql-toolbar button.ql-active,
.rich-text-editor .ql-snow .ql-toolbar button:focus,
.rich-text-editor .ql-snow .ql-toolbar button:hover,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-item:hover,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.rich-text-editor .ql-snow.ql-toolbar .ql-picker-label:hover,
.rich-text-editor .ql-snow.ql-toolbar button.ql-active,
.rich-text-editor .ql-snow.ql-toolbar button:focus,
.rich-text-editor .ql-snow.ql-toolbar button:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}
.dark .rich-text-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected,
.dark .rich-text-editor .ql-snow .ql-toolbar .ql-picker-item:hover,
.dark .rich-text-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.dark .rich-text-editor .ql-snow .ql-toolbar .ql-picker-label:hover,
.dark .rich-text-editor .ql-snow .ql-toolbar button.ql-active,
.dark .rich-text-editor .ql-snow .ql-toolbar button:focus,
.dark .rich-text-editor .ql-snow .ql-toolbar button:hover,
.dark .rich-text-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.dark .rich-text-editor .ql-snow.ql-toolbar .ql-picker-item:hover,
.dark .rich-text-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.dark .rich-text-editor .ql-snow.ql-toolbar .ql-picker-label:hover,
.dark .rich-text-editor .ql-snow.ql-toolbar button.ql-active,
.dark .rich-text-editor .ql-snow.ql-toolbar button:focus,
.dark .rich-text-editor .ql-snow.ql-toolbar button:hover {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

/* ================================================================================================================ */
/* 9. Secondary Header */
/* ================================================================================================================ */
.secondary-header-light {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-bottom-width: 1px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}
.secondary-header-dark,
.secondary-header-light {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
}
.secondary-header-dark {
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  border-bottom-width: 1px;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

/* ================================================================================================================ */
/* 10. Side Nav */
/* ================================================================================================================ */
.side-nav {
  flex: 1 1 auto;
  flex-direction: column;
  flex-shrink: 0;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 20;
}
.side-nav-expand {
  height: 100vh;
  position: sticky;
  top: 0;
}
.side-nav-content {
  height: calc(90vh - 4rem);
  overflow-y: auto;
}
.side-nav-light {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  border-right-width: 1px;
}
.side-nav-dark,
.side-nav-light {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
}
.side-nav-dark {
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
  border-right-width: 1px;
}
.side-nav-transparent {
  background-color: initial;
}
.stacked-side-nav {
  display: flex;
  flex: 1 1 auto;
  flex-shrink: 0;
  height: 100vh;
  position: sticky;
  top: 0;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 20;
}
.stacked-side-nav-content {
  height: calc(100vh - 4rem);
  overflow-y: auto;
}
.stacked-side-nav-mini {
  z-index: 10;
}
.stacked-side-nav-secondary {
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.stacked-side-nav-mini-light {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  border-right-width: 1px;
}
.stacked-side-nav-mini-dark,
.stacked-side-nav-mini-light {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
}
.stacked-side-nav-mini-dark {
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
  border-right-width: 1px;
}
.stacked-side-nav-secondary-light {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  border-right-width: 1px;
}
.stacked-side-nav-secondary-dark,
.stacked-side-nav-secondary-light {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
}
.stacked-side-nav-secondary-dark {
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
  border-right-width: 1px;
}

/* ================================================================================================================ */
/* 11. Header */
/* ================================================================================================================ */
.header {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  display: flex;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 20;
}
.dark .header,
.header {
  --tw-bg-opacity: 1;
}
.dark .header {
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
.header-wrapper {
  justify-content: space-between;
  padding: 0 1rem;
  position: relative;
  width: 100%;
}
.header-action,
.header-wrapper {
  align-items: center;
  display: flex;
}
.header-action-item {
  border-radius: 9999px;
  cursor: pointer;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  padding: 0.5rem;
}
.header-action-item-hoverable {
  transition-duration: 0.3s;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.header-action-item-hoverable:hover {
  --tw-bg-opacity: 0.05;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}
.dark .header-action-item-hoverable:hover,
.header-action-item-hoverable:hover {
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
.dark .header-action-item-hoverable:hover {
  --tw-bg-opacity: 0.4;
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

/* ================================================================================================================ */
/* 12. Upload */
/* ================================================================================================================ */
.upload {
  display: inline-block;
  position: relative;
}
.upload.disabled {
  opacity: 0.6;
}
.upload.disabled,
.upload.disabled .upload-input {
  cursor: not-allowed;
}
.upload-input {
  bottom: 0;
  display: none;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.upload-input.draggable {
  cursor: pointer;
  display: block;
  opacity: 0;
  width: 100%;
}
.upload-draggable {
  --tw-border-opacity: 1;
  align-items: center;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  border-radius: 0.5rem;
  border-style: dashed;
  border-width: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-height: 7rem;
}
.dark .upload-draggable {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.upload-file-list {
  margin-top: 1rem;
}
.upload-file-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 4rem;
}
[dir="ltr"] .upload-file-info {
  margin-left: 0.75rem;
}
[dir="rtl"] .upload-file-info {
  margin-right: 0.75rem;
}
.upload-file {
  align-items: center;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  border-radius: 0.5rem;
  border-width: 1px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.dark .upload-file,
.upload-file {
  --tw-border-opacity: 1;
}
.dark .upload-file {
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.upload-file-thumbnail {
  align-items: center;
  display: flex;
  height: 4rem;
  justify-content: center;
  padding: 0.5rem;
  width: 4rem;
}
.upload-file-image {
  max-width: 100%;
}
.upload-file-remove {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.75rem;
}

/* ================================================================================================================ */
/* 13. Tooltip */
/* ================================================================================================================ */
.tooltip {
  --tw-text-opacity: 1;
  border-radius: 0.5rem;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  max-width: 250px;
  padding: 0.5rem 1rem;
  position: relative;
  z-index: 50;
}
.tooltip-wrapper {
  display: inline-flex;
}

/* ================================================================================================================ */
/* 14. Toast */
/* ================================================================================================================ */
.toast {
  position: fixed;
  z-index: 40;
}

/* ================================================================================================================ */
/* 15. Timeline */
/* ================================================================================================================ */
.timeline {
  align-items: flex-start;
  flex-direction: column;
}
.timeline-item {
  display: flex;
  min-height: 70px;
}
.timeline-item-last {
  min-height: max-content;
}
.timeline-item-media {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.timeline-item-media-content {
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}
.timeline-item-media-default {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  border-radius: 9999px;
  height: 1rem;
  width: 1rem;
}
.dark .timeline-item-media-default {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.timeline-item-wrapper {
  display: flex;
  flex: 1 1 auto;
}
.timeline-item-content {
  margin-left: 1rem;
  padding-bottom: 1.5rem;
  padding-top: 0.125rem;
  width: 100%;
}
[dir="rtl"] .timeline-item-content {
  margin-right: 1rem;
}
.timeline-item-content-last {
  padding-bottom: 0;
}
.timeline-connect {
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  flex-grow: 1;
  height: 100%;
  width: 2px;
}
.dark .timeline-connect,
.timeline-connect {
  --tw-bg-opacity: 1;
}
.dark .timeline-connect {
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

/* ================================================================================================================ */
/* 16. Tag */
/* ================================================================================================================ */
.tag {
  align-items: center;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 1px;
  display: inline-flex;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  padding: 0.25rem 0.625rem;
  white-space: nowrap;
}
.dark .tag,
.tag {
  --tw-border-opacity: 1;
}
.dark .tag {
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.tag-affix {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
  border-radius: 9999px;
  height: 0.5rem;
  width: 0.5rem;
}
.tag-prefix {
  margin-right: 0.375rem;
}
.tag-suffix,
[dir="rtl"] .tag-prefix {
  margin-left: 0.375rem;
}
[dir="rtl"] .tag-suffix {
  margin-right: 0.375rem;
}

/* ================================================================================================================ */
/* 17. Tab nav and list */
/* ================================================================================================================ */

.tab-list {
  align-items: center;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}
.tab-list-underline {
  border-bottom-width: 1px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}
.dark .tab-list-underline,
.tab-list-underline {
  --tw-border-opacity: 1;
}
.dark .tab-list-underline {
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.tab-nav {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  justify-content: center;
  transition-duration: 0.2s;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    -webkit-backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.tab-nav.tab-nav-disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.tab-nav-underline {
  border-bottom-width: 2px;
  border-color: #0000;
  padding: 0.75rem 1.25rem;
}
.tab-nav-pill {
  border-radius: 0.375rem;
  margin-right: 0.25rem;
  padding: 0.5rem 1.25rem;
}
[dir="rtl"] .tab-nav-pill {
  margin-left: 0.25rem;
}
.tab-nav-icon {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
[dir="ltr"] .tab-nav-icon {
  margin-right: 0.375rem;
}
[dir="rtl"] .tab-nav-icon {
  margin-left: 0.375rem;
}

/* ================================================================================================================ */
/* 18. Table */
/* ================================================================================================================ */

.table-default {
  min-width: 100%;
  table-layout: auto;
}
.table-default > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  --tw-divide-opacity: 1;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
  border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
}
.dark .table-default > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-divide-opacity));
}
.table-default thead {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.dark .table-default thead {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}
.table-default thead > tr > th {
  color: rgb(107 114 128 / var(--tw-text-opacity));
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 1rem;
  padding: 0.75rem 1.5rem;
  text-align: left;
  text-transform: uppercase;
}
.dark .table-default thead > tr > th,
.table-default thead > tr > th {
  --tw-text-opacity: 1;
}
.dark .table-default thead > tr > th {
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.table-default tbody > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  --tw-divide-opacity: 1;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
  border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
}
.dark .table-default tbody > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-divide-opacity));
}
.table-default tbody > tr > td {
  padding: 1rem 1.5rem;
}
.table-default tfoot > tr > td {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  border-top-width: 1px;
  padding: 0.75rem 1.5rem;
}
.dark .table-default tfoot > tr > td {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}
.table-default.borderless-row tbody > :not([hidden]) ~ :not([hidden]) {
  border-style: none;
}
.table-flex {
  min-width: 100%;
}
.table-flex > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  --tw-divide-opacity: 1;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
  border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
}
.dark .table-flex > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-divide-opacity));
}
.table-flex .thead {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.dark .table-flex .thead {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}
.table-flex .thead .tr .th {
  color: rgb(107 114 128 / var(--tw-text-opacity));
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 1rem;
  padding: 0.75rem 1.5rem;
  text-align: left;
  text-transform: uppercase;
}
.dark .table-flex .thead .tr .th,
.table-flex .thead .tr .th {
  --tw-text-opacity: 1;
}
.dark .table-flex .thead .tr .th {
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.table-flex .tbody > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  --tw-divide-opacity: 1;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
  border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
}
.dark .table-flex .tbody > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-divide-opacity));
}
.table-flex .tbody .tr .td {
  padding: 1rem 1.5rem;
}
.table-flex .tfoot .tr .td {
  border-top-width: 1px;
  padding: 0.75rem 1.5rem;
}
.dark .table-flex .tfoot .tr .td {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}
.table-virtual > div > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  --tw-divide-opacity: 1;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
  border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
}
.dark .table-virtual > div > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-divide-opacity));
}
.table-compact thead > tr > th {
  font-size: 0.75rem;
  line-height: 1rem;
}
.table-compact .thead > .tr > .th,
.table-compact tbody > tr > td,
.table-compact thead > tr > th {
  padding: 0.5rem 0.75rem;
}
.table-compact .thead > .tr > .th {
  font-size: 0.75rem;
  line-height: 1rem;
}
.table-compact .tbody > .tr > .td {
  padding: 0.5rem 0.75rem;
}
.table-hover > tbody > tr:hover > * {
  background-color: #f3f4f680;
}
.dark .table-hover > tbody > tr:hover > * {
  background-color: #37415166;
}
.table-hover > .tbody > .tr:hover > * {
  background-color: #f3f4f680;
}
.dark .table-hover > .tbody > .tr:hover > * {
  background-color: #37415166;
}
.table-resizer {
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  border-right-width: 2px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 0.5rem;
  z-index: 10;
}
.dark .table-resizer,
.table-resizer {
  --tw-border-opacity: 1;
}
.dark .table-resizer {
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.table-resizer {
  touch-action: none;
}
td,
th {
  word-wrap: break-word;
}
/* ================================================================================================================ */
/* 19. Switcher */
/* ================================================================================================================ */
.switcher {
  align-items: center;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  border-radius: 1.5rem;
  cursor: pointer;
  display: inline-flex;
  height: 1.5rem;
  min-width: 2.75rem;
  position: relative;
  transition-duration: 0.2s;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.dark .switcher,
.switcher {
  --tw-bg-opacity: 1;
}
.dark .switcher {
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.switcher input {
  display: none;
}
.switcher.switcher-checked .switcher-toggle,
.switcher.switcher-checked .switcher-toggle-loading {
  left: calc(100% - 1.375rem);
}
.switcher.switcher-checked .switcher-content {
  margin: 0 1.65rem 0 0.45rem;
}
.switcher.switcher-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.switcher-toggle {
  height: 1.25rem;
  left: 0.125rem;
  position: absolute;
  top: 0.125rem;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 1.25rem;
}
.switcher-toggle:before {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 0.75rem;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.switcher-content {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin: 0 0.45rem 0 1.65rem;
  transition-duration: 0.2s;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.switcher-toggle-loading {
  height: 1.25rem;
  left: 0.125rem;
  position: absolute;
  top: 0.125rem;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 1.25rem;
}
.dark .switcher-uncheck-loading,
.switcher-checked-loading {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

/* ================================================================================================================ */
/* 20. Steps */
/* ================================================================================================================ */
.steps {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.steps.steps-vertical {
  align-items: flex-start;
  flex-direction: column;
}
.step-item {
  align-items: center;
  display: flex;
}
.step-item.step-item-vertical {
  align-items: flex-start;
  flex-direction: column;
}
.step-item-icon,
.step-item-wrapper {
  align-items: center;
  display: flex;
}
.step-item-icon {
  border-radius: 9999px;
  font-size: 1.125rem;
  font-weight: 600;
  height: 2.25rem;
  justify-content: center;
  line-height: 1.75rem;
  min-width: 2.25rem;
  width: 2.25rem;
}
.step-item-icon.step-item-icon-pending {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  border-width: 2px;
}
.dark .step-item-icon.step-item-icon-pending {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.step-item-icon.step-item-icon-current {
  border-width: 2px;
}
.step-item-content {
  position: relative;
}
[dir="ltr"] .step-item-content {
  margin-left: 0.75rem;
}
[dir="rtl"] .step-item-content {
  margin-right: 0.75rem;
}
.step-item-title {
  color: rgb(75 85 99 / var(--tw-text-opacity));
  display: block;
  font-weight: 700;
  white-space: nowrap;
}
.dark .step-item-title,
.step-item-title {
  --tw-text-opacity: 1;
}
.dark .step-item-title {
  color: rgb(209 213 219 / var(--tw-text-opacity));
}
.step-item-title.step-item-title-error {
  --tw-text-opacity: 1;
}
.step-item-icon-error,
.step-item-title.step-item-title-error {
  color: rgb(239 68 68 / var(--tw-text-opacity));
}
.step-item-icon-error {
  --tw-border-opacity: 1;
  --tw-text-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
  border-width: 2px;
}
.step-clickable {
  cursor: pointer;
}
.step-connect {
  height: 2px;
  margin-left: 0.625rem;
  width: 100%;
}
[dir="rtl"] .step-connect {
  margin-right: 0.625rem;
}
.step-connect.inactive {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}
.dark .step-connect.inactive {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.step-connect.step-connect-vertical {
  min-height: 3.5rem;
  width: 2px;
}
[dir="ltr"] .step-connect.step-connect-vertical {
  margin-left: 1rem;
}
[dir="rtl"] .step-connect.step-connect-vertical {
  margin-right: 1rem;
}

/* ================================================================================================================ */
/* 21. Skeleton */
/* ================================================================================================================ */
.skeleton {
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  display: flex;
}
.dark .skeleton,
.skeleton {
  --tw-bg-opacity: 1;
}
.dark .skeleton {
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.skeleton-circle {
  border-radius: 9999px;
  height: 2.5rem;
  width: 2.5rem;
}
.skeleton-block {
  border-radius: 0.25rem;
  height: 0.625rem;
  width: 100%;
}

/* ================================================================================================================ */
/* 22. Select */
/* ================================================================================================================ */
.select .select__control--is-disabled {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  color: rgb(156 163 175 / var(--tw-text-opacity));
  cursor: not-allowed;
}
.dark .select .select__control--is-disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}
.select .select__control--is-disabled .select__placeholder {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}
.select .select__control--is-disabled .select__multi-value {
  opacity: 0.6;
}
.select .select__placeholder {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.select .select__multi-value {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}
.dark .select .select__multi-value {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.select .select__multi-value .select__multi-value__label {
  color: inherit;
}
.select .select__multi-value__remove:hover {
  --tw-text-opacity: 1;
  background-color: initial;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}
.dark .select .select__multi-value__remove:hover {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.select.select-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.select.select-lg .select-dropdown-indicator {
  font-size: 1.5rem;
  line-height: 2rem;
}
.select .select__single-value {
  color: inherit;
}
.select .select__single-value--is-disabled {
  opacity: 0.7;
}
.dark .select .select__input input {
  --tw-text-opacity: 1 !important;
  color: rgb(243 244 246 / var(--tw-text-opacity)) !important;
}
.select .select__control {
  height: auto;
}
.dark .select .select__control {
  background-color: initial;
}
.select .select__control.hide-single-value .select__single-value {
  display: none !important;
}
.select__menu {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.dark .select__menu,
.select__menu {
  --tw-bg-opacity: 1;
}
.dark .select__menu {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.select-option {
  align-items: center;
  cursor: default;
  display: flex;
  justify-content: space-between;
  padding: 0.375rem 0.5rem;
}
.select-option:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.dark .select-option:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.select-option.selected {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.dark .select-option.selected {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}
.select-option.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.select-option.disabled:hover {
  background-color: initial;
}
.dark .select-option.disabled {
  opacity: 0.3;
}
.select-dropdown-indicator {
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
}
.select-dropdown-indicator:hover {
  opacity: 0.7;
}
.select-clear-indicator {
  font-size: 1.125rem;
  line-height: 1.75rem;
  padding: 0.5rem;
}
.select-clear-indicator:hover {
  opacity: 0.7;
}

/* ================================================================================================================ */
/* 23. Segment  */
/* ================================================================================================================ */
.segment {
  display: flex;
}
[dir="ltr"] .segment > .segment-item-default:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
[dir="ltr"] .segment > .segment-item-default:last-child:not(.picker),
[dir="rtl"] .segment > .segment-item-default:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
[dir="rtl"] .segment > .segment-item-default:last-child:not(.picker) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
[dir="ltr"] .segment > .segment-item-default:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -1px;
}
[dir="rtl"] .segment > .segment-item-default:not(:first-child) {
  margin-right: -1px;
}
[dir="ltr"] .segment > .segment-item-default:not(:last-child),
[dir="rtl"] .segment > .segment-item-default:not(:first-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
[dir="rtl"] .segment > .segment-item-default:not(:last-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.segment-item-default {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  border-radius: 0.375rem;
  border-width: 1px;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  cursor: pointer;
  font-weight: 600;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
}
.segment-item-default:focus {
  outline: 2px solid #0000;
  outline-offset: 2px;
}
.dark .segment-item-default {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
@media (min-width: 768px) {
  .segment-item-default {
    width: auto;
  }
}
.segment-item-default:not(.segment-item-active):not(
    .segment-item-disabled
  ):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.segment-item-default:not(.segment-item-active):not(
    .segment-item-disabled
  ):active {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.dark
  .segment-item-default:not(.segment-item-active):not(
    .segment-item-disabled
  ):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.dark
  .segment-item-default:not(.segment-item-active):not(
    .segment-item-disabled
  ):active {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}
.segment-item-default.segment-item-active {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.dark .segment-item-default.segment-item-active {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}
.segment-item-default.segment-item-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

/* ================================================================================================================ */
/* 24. Radio  */
/* ================================================================================================================ */
.radio {
  --tw-border-opacity: 1;
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  appearance: none;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 1px;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  cursor: pointer;
  display: inline-block;
  flex-shrink: 0;
  height: 1.25rem;
  padding: 0;
  transition-duration: 0.15s;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  width: 1.25rem;
}
.dark .radio {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.radio:checked {
  background-color: currentColor;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E");
  border-color: #0000;
}
.radio.disabled {
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  color: rgb(229 231 235 / var(--tw-text-opacity));
  cursor: not-allowed;
}
.dark .radio.disabled,
.radio.disabled {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}
.dark .radio.disabled {
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.radio-label {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
}
.radio-label.disabled {
  cursor: not-allowed;
}
.radio-group {
  display: inline-flex;
}
.radio-group.vertical {
  flex-direction: column;
}

/* ================================================================================================================ */
/* 25. Progress  */
/* ================================================================================================================ */
.progress {
  display: inline-block;
}
.progress.circle,
.progress.line {
  position: relative;
  width: 100%;
}
.progress.line {
  align-items: center;
  display: flex;
}
.progress-bg {
  border-radius: 9999px;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
[dir="ltr"] .progress-info.line {
  margin-left: 0.5rem;
}
[dir="rtl"] .progress-info.line {
  margin-right: 0.5rem;
}
.progress-inner {
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  border-radius: 9999px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.dark .progress-inner,
.progress-inner {
  --tw-bg-opacity: 1;
}
.dark .progress-inner {
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.progress-wrapper {
  width: 100%;
}
.progress-circle {
  position: relative;
}
.progress-circle-info {
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.progress-circle-trail {
  stroke: currentColor;
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.dark .progress-circle-trail {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}
.progress-circle-stroke {
  stroke: currentColor;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

/* ================================================================================================================ */
/* 26. Pagination  */
/* ================================================================================================================ */
.pagination {
  border-radius: 0.375rem;
  z-index: 0;
}
.pagination,
.pagination-pager {
  align-items: center;
  display: inline-flex;
  position: relative;
}
.pagination-pager {
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  height: 2rem;
  justify-content: center;
  line-height: 1.25rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  -webkit-user-select: none;
  user-select: none;
  width: 2rem;
}
.pagination-pager-inactive {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}
.pagination-pager-inactive:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.dark .pagination-pager-inactive {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.dark .pagination-pager-inactive:hover {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.pagination-pager-disabled {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
  cursor: not-allowed;
}
.dark .pagination-pager-disabled {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}
.pagination-total {
  color: rgb(107 114 128 / var(--tw-text-opacity));
  font-weight: 600;
  margin-right: 0.75rem;
}
.dark .pagination-total,
.pagination-total {
  --tw-text-opacity: 1;
}
.dark .pagination-total {
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.pagination-pager-next {
  margin-right: 0.25rem;
}
[dir="rtl"] .pagination-pager-next {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.pagination-pager-prev {
  margin-left: 0.25rem;
}
[dir="rtl"] .pagination-pager-prev {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

/* ================================================================================================================ */
/* 27. Notification  */
/* ================================================================================================================ */
.notification {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
  border-radius: 0.5rem;
  border-width: 1px;
  margin-bottom: 0.75rem;
  position: relative;
}
.dark .notification,
.notification {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.dark .notification {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  border-color: #0000;
}
.notification-content {
  display: flex;
  padding: 1rem;
}
.notification-content.no-child {
  align-items: center;
}
.notification-title {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
  font-weight: 600;
}
.dark .notification-title {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.notification-close {
  top: 1.25rem;
}
[dir="ltr"] .notification-close {
  right: 1rem;
}
[dir="rtl"] .notification-close {
  left: 1rem;
}

/* ================================================================================================================ */
/* 28. Menu Item  */
/* ================================================================================================================ */
.menu-item {
  align-items: center;
  border-radius: 0.375rem;
  column-gap: 0.5rem;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  white-space: nowrap;
  width: 100%;
}
.menu-item.menu-item-light {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}
.menu-item.menu-item-light.menu-item-hoverable:hover {
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.menu-item.menu-item-light.menu-item-active,
.menu-item.menu-item-light.menu-item-hoverable:hover {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}
.menu-item.menu-item-light.menu-item-active,
.menu-item.menu-item-light.menu-item-active:hover {
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}
.menu-item.menu-item-light.menu-item-active:hover {
  --tw-bg-opacity: 1;
}
.dark .menu-item.menu-item-dark,
.menu-item.menu-item-dark {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.menu-item.menu-item-dark.menu-item-hoverable:hover {
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}
.menu-item.menu-item-dark.menu-item-active,
.menu-item.menu-item-dark.menu-item-hoverable:hover {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.menu-item.menu-item-dark.menu-item-active,
.menu-item.menu-item-dark.menu-item-active:hover {
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.menu-item.menu-item-dark.menu-item-active:hover {
  --tw-bg-opacity: 1;
}
.menu-item.menu-item-themed {
  --tw-text-opacity: 0.8;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.menu-item.menu-item-themed.menu-item-hoverable:hover {
  --tw-bg-opacity: 0.1;
  --tw-text-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}
.menu-item.menu-item-themed.menu-item-active {
  --tw-bg-opacity: 0.3;
  --tw-text-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.menu-item.menu-item-themed.menu-item-active:hover {
  --tw-bg-opacity: 0.3;
}
.menu-item.menu-item-transparent {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}
.dark .menu-item.menu-item-transparent {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.menu-item.menu-item-transparent.menu-item-hoverable:hover {
  --tw-text-opacity: 1;
  background-color: #1118271a;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}
.dark .menu-item.menu-item-transparent.menu-item-hoverable:hover {
  --tw-text-opacity: 1;
  background-color: #ffffff1a;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.menu-item.menu-item-transparent.menu-item-active {
  --tw-text-opacity: 1;
  background-color: rgba(17, 24, 39, 0.075);
  color: rgb(17 24 39 / var(--tw-text-opacity));
}
.dark .menu-item.menu-item-transparent.menu-item-active {
  --tw-text-opacity: 1;
  background-color: #ffffff1a;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.menu-collapse-item {
  align-items: center;
  border-radius: 0.375rem;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  height: 2.5rem;
  justify-content: space-between;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  -webkit-user-select: none;
  user-select: none;
}
.menu-collapse-item.menu-collapse-item-light {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}
.menu-collapse-item.menu-collapse-item-light:hover {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  color: rgb(17 24 39 / var(--tw-text-opacity));
}
.menu-collapse-item.menu-collapse-item-dark {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.menu-collapse-item.menu-collapse-item-dark:hover {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.dark .menu-collapse-item.menu-collapse-item-dark {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.dark .menu-collapse-item.menu-collapse-item-dark:hover {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.menu-collapse-item.menu-collapse-item-themed {
  --tw-text-opacity: 0.8;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.menu-collapse-item.menu-collapse-item-themed:hover {
  --tw-bg-opacity: 0.1;
  --tw-text-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}
.menu-collapse-item.menu-collapse-item-transparent {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}
.menu-collapse-item.menu-collapse-item-transparent:hover {
  --tw-text-opacity: 1;
  background-color: #1118271a;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}
.dark .menu-collapse-item.menu-collapse-item-transparent {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.dark .menu-collapse-item.menu-collapse-item-transparent:hover {
  --tw-text-opacity: 1;
  background-color: #ffffff1a;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.menu-item-divider {
  border-bottom-width: 1px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.dark .menu-item-divider,
.menu-item-divider {
  --tw-border-opacity: 1;
}
.dark .menu-item-divider {
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.menu-item-disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.menu-item-link {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
}
.menu-title {
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  text-transform: uppercase;
}
.menu-title.menu-title-light {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}
.menu-title.menu-title-dark {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}
.menu-title.menu-title-themed {
  --tw-text-opacity: 0.5;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.menu-light {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.menu-dark,
.menu-light {
  --tw-bg-opacity: 1;
}
.menu-dark {
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
.menu-transparent {
  background-color: initial;
}

/* ================================================================================================================ */
/* 29. Input  */
/* ================================================================================================================ */
.input-group {
  align-items: center;
  display: flex;
}
.input-group .input {
  z-index: 10;
}
.input-group .input:focus,
.input-group .input:focus-within {
  z-index: 20;
}
.input-group .date-picker {
  z-index: 11;
}
.input-group .date-picker.picker-expanded,
.input-group .input-suffix-end,
.input-group .input-suffix-start,
.input-group .select__control--is-focused {
  z-index: 20;
}
[dir="ltr"] .input-group > .button:first-child,
[dir="ltr"] .input-group > .input-addon:first-child,
[dir="ltr"] .input-group > .input:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
[dir="ltr"] .input-group > .button:last-child:not(.picker),
[dir="ltr"] .input-group > .input-addon:last-child:not(.picker),
[dir="ltr"] .input-group > .input:last-child:not(.picker),
[dir="rtl"] .input-group > .button:first-child,
[dir="rtl"] .input-group > .input-addon:first-child,
[dir="rtl"] .input-group > .input:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
[dir="rtl"] .input-group > .button:last-child:not(.picker),
[dir="rtl"] .input-group > .input-addon:last-child:not(.picker),
[dir="rtl"] .input-group > .input:last-child:not(.picker) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
[dir="ltr"] .input-group > .button:not(:first-child),
[dir="ltr"] .input-group > .input-addon:not(:first-child),
[dir="ltr"] .input-group > .input:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -1px;
}
[dir="rtl"] .input-group > .button:not(:first-child),
[dir="rtl"] .input-group > .input-addon:not(:first-child),
[dir="rtl"] .input-group > .input:not(:first-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  margin-right: -1px;
}
[dir="ltr"] .input-group > .button:not(:last-child),
[dir="ltr"] .input-group > .input-addon:not(:last-child),
[dir="ltr"] .input-group > .input:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
[dir="rtl"] .input-group > .button:not(:last-child),
[dir="rtl"] .input-group > .input-addon:not(:last-child),
[dir="rtl"] .input-group > .input:not(:last-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
[dir="ltr"] .input-group > :first-child .input,
[dir="ltr"] .input-group > :first-child .select__control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
[dir="ltr"] .input-group > :last-child .input,
[dir="ltr"] .input-group > :last-child .select__control,
[dir="rtl"] .input-group > :first-child .input,
[dir="rtl"] .input-group > :first-child .select__control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
[dir="rtl"] .input-group > :last-child .input,
[dir="rtl"] .input-group > :last-child .select__control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
[dir="ltr"] .input-group > :not(:first-child) .input,
[dir="ltr"] .input-group > :not(:first-child) .select__control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -1px;
}
[dir="rtl"] .input-group > :not(:first-child) .input,
[dir="rtl"] .input-group > :not(:first-child) .select__control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  margin-right: -1px;
}
[dir="ltr"] .input-group > :not(:last-child) .input,
[dir="ltr"] .input-group > :not(:last-child) .select__control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
[dir="rtl"] .input-group > :not(:last-child) .input,
[dir="rtl"] .input-group > :not(:last-child) .select__control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.input {
  --tw-border-opacity: 1;
  appearance: none;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  border-radius: 0.375rem;
  border-width: 1px;
  padding: 0.5rem 0.75rem;
  transition-duration: 0.15s;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    -webkit-backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
}
.input:focus,
.input:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.input:focus {
  outline: 2px solid #0000;
  outline-offset: 2px;
}
.dark .input {
  --tw-border-opacity: 1;
  background-color: initial;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.dark .input,
.input::placeholder {
  --tw-text-opacity: 1;
}
.input::placeholder {
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.input.input-invalid {
  --tw-border-opacity: 1;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.input.input-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.input-addon {
  align-items: center;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  border-radius: 0.375rem;
  border-width: 1px;
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
}
.dark .input-addon,
.input-addon {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
}
.dark .input-addon {
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.input-textarea {
  min-height: 7rem;
}
.input-wrapper {
  display: flex;
  position: relative;
  width: 100%;
}
.input-disabled {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  color: rgb(156 163 175 / var(--tw-text-opacity));
  cursor: not-allowed;
}
.dark .input-disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}
.input-disabled::placeholder {
  opacity: 0.7;
}
.input-suffix-start {
  --tw-translate-y: -50%;
  position: absolute;
  top: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
[dir="ltr"] .input-suffix-start {
  left: 0.625rem;
}
[dir="rtl"] .input-suffix-start {
  right: 0.625rem;
}
.input-suffix-end {
  --tw-translate-y: -50%;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
[dir="ltr"] .input-suffix-end {
  right: 0.625rem;
}
[dir="rtl"] .input-suffix-end {
  left: 0.625rem;
}

/* ================================================================================================================ */
/* 30. Form Item & Label  */
/* ================================================================================================================ */
.form-item {
  margin-bottom: 1.40rem;
  position: relative;
}
.form-item.horizontal {
  display: flex;
  flex: 1 1 auto;
}
.form-item.horizontal .form-label {
  justify-content: flex-end;
}
.form-item.vertical {
  display: flex;
  flex-direction: column;
}
.form-item.inline {
  margin-right: 0.75rem;
}
[dir="rtl"] .form-item.inline {
  margin-left: 0.75rem;
}
@media (min-width: 768px) {
  .form-item.inline {
    display: inline-flex;
  }
}
.form-label {
  align-items: center;
  display: flex;
  font-weight: 600;
}
.form-explain,
.form-label.invalid {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}
.form-explain {
  position: absolute;
}
.form-container.inline {
  align-items: center;
}
@media (min-width: 768px) {
  .form-container.inline {
    display: flex;
  }
}

/* ================================================================================================================ */
/* 31. Dialog  */
/* ================================================================================================================ */
.dialog {
  margin-left: auto;
  margin-right: auto;
  outline: none;
}
@media (min-width: 640px) {
  .dialog {
    max-width: 36rem;
  }
}
@media (min-width: 768px) {
  .dialog {
    max-width: 42rem;
  }
}
@media (min-width: 1024px) {
  .dialog {
    max-width: 56rem;
  }
}
@media (min-width: 1280px) {
  .dialog {
    max-width: 72rem;
  }
}
.dialog-content {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 0.5rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  height: 100%;
  padding: 1.5rem;
  position: relative;
}
.dark .dialog-content {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
@media (min-width: 640px) {
  .dialog-content {
    margin-bottom: 4rem;
    margin-top: 4rem;
  }
}
.dialog-overlay {
  --tw-bg-opacity: 0.6;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.15s ease-in-out;
  z-index: 30;
}
.dark .dialog-overlay {
  --tw-bg-opacity: 0.8;
}
.dialog-overlay-after-open {
  opacity: 1;
}
.dialog-overlay-before-close {
  opacity: 0;
}

/* ================================================================================================================ */
/* 32. Checkbox  */
/* ================================================================================================================ */
.checkbox {
  padding: 0;
}
.checkbox {
  --tw-border-opacity: 1;
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  appearance: none;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  border-radius: 0.25rem;
  border-width: 1px;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  cursor: pointer;
  display: inline-block;
  flex-shrink: 0;
  height: 1.25rem;
  transition-duration: 0.15s;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  width: 1.25rem;
}
.dark .checkbox {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.checkbox:checked {
  background-color: currentColor;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E");
  border-color: #0000;
}
.checkbox.disabled {
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  color: rgb(229 231 235 / var(--tw-text-opacity));
  cursor: not-allowed;
}
.checkbox.disabled,
.dark .checkbox.disabled {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}
.dark .checkbox.disabled {
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.checkbox-label {
  cursor: pointer;
  display: inline-flex;
  margin-right: 0.75rem;
}
.checkbox-label.disabled {
  cursor: not-allowed;
}
